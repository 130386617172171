// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
@import url("https://cdn.syncfusion.com/ej2/material.css");

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}


.closeStatusColor{
  background-color: #11bb11;
  color: white;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  // width:2px;
  border-radius: 10px;
  background: #c1c1c1;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #c1c1c1;
}
.btn.btn-success {
  background-color: #cc0001 !important;
  border-color: #cc0001 !important;
}
.widthfixed {
  button {
    width: 100px !important;
  }
}
.titles {
  font-weight: 800 !important;
  font-size: 18px !important;
}
img.cmsImage {
  height: 400px !important;
  width: 400px !important;
  object-fit: cover !important;
}
.cms-des {
  font-size: 14px !important;
}
.star-color {
  color: #cc0001 !important;
}
.btn-login {
  background-color: #cc0001 !important;
  height: 40px !important;
  line-height: 10px !important;
  border-color: #cc0001 !important;
}
.mr-3 {
  margin-right: 20px !important;
}
.btn-color {
  background-color: #f0df20;
  color: #000;
  &:hover {
    background-color: #fcee49;
    color: #000;
    cursor: pointer;
  }
}
.labelMainDiv {
  display: flex;
  height: 45px;
  background-color: #f3f6f9;
  border: 1px solid #c4c4c4;
  border-radius: 5px;

  .lableForImage {
    align-items: center;
    display: flex;
    padding: 8px;
    background-color: lightgray;
    margin: 10px;
    border-radius: 7px;
    cursor: pointer;
  }
  .labelselectfile {
    align-items: center;
    display: flex;
    margin: 10px;
  }
}

/* This used to work for the parent element of button divs */
/* But it does not work with newer browsers, the below doesn't hide the play button parent div */

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}
.btn-active-color {
  background-color: #fcee49;
  color: #000;
  cursor: pointer;
  border: none;
}

.all-info-white-banner {
  background-color: #fff;
  padding: 30px;
  border-radius: 4px;
  .sub-grid-info-image {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    .sub-grid-info-image-items {
      // border: 1px solid #d0d0d0;
      height: 140px;
      border-radius: 4px;
    }
  }
  .page-title-alignment {
    margin: 0 0 50px 0;
    h1 {
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
      color: #323232;
      margin: 0 0 20px 0;
    }
  }
  .first-box-design {
    border: 1px solid #777777;
    border-radius: 4px;
    padding: 30px;
    .button-center-alignment-form {
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        margin-right: 20px;
        button {
          padding: 8px 16px;
          border-radius: 4px;
          font-size: 14px;
        }
      }
    }
    .first-box-input-grid {
      padding: 0 0 50px 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      .input {
        label {
          font-size: 16px;
          line-height: 20px;
          color: #777;
          display: block;
          padding: 0 0 5px 0;
        }
        input {
          border: 1px solid #d0d0d0;
          width: 100%;
          height: 45px;
          border-radius: 4px;
          padding: 0 16px;
          font-size: 16px;
          outline: none;
          color: #323232;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.init-button {
  background-color: #fff;
  border: 1px solid #e83b3b;
  color: #e83b3b;
}

.select-button {
  background-color: #e83b3b;
  color: #fff;
  border: none;
}

.submit-button {
  display: flex;
  justify-content: center;
  button {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    background-color: #e83b3b;
    color: #fff;
    border: none;
  }
}

.back-page-arrow-mui-icon {
  margin-left: 10px;
  padding-top: 10px;
}

.errors {
  color: red;
  font-size: 12px;
  top: 5px;
}

.kt-radio-inline {
  margin-top: 10px;
  .kt-radio {
    margin-right: 10px;
    font-size: 16px;
    input {
      margin-right: 10px;
    }
  }
}
.btn-kyc-info {
  background-color: #f0df20;
  color: black;
  padding: 10px;
  border: none;
  width: 140px;
  border-radius: 10px;
}
.btn-kyc-ok {
  background-color: #f0df20;
  color: black;
  padding: 10px;
  border: none;
  width: 140px;
}
.no-kyc-data {
  text-align: center;
  margin-top: 50px;
}
.btn-kyc-Approve {
  background-color: #f0df20;
  color: black;
  padding: 8px;
  border: none;
  width: 140px;
  font-size: 25px;
  border-radius: 10px;
}
.btn-kyc-Reject {
  background-color: #f0df20;
  color: black;
  padding: 8px;
  border: none;
  width: 140px;
  margin-left: 10px;
  font-size: 25px;
  border-radius: 10px;
}
.submit-kyc {
  background-color: #f0df20;
  color: black;
  padding: 10px;
  border: none;
  width: 140px;
  margin-left: 10px;
  font-size: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}
.reasonod-reject-data {
  margin-top: 15px;
  height: 100px;
  width: 500px;
  border-color: #000;
}
.contain-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .approve-reject-alignment {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
.close-icon {
  margin-left: 25px;
  margin-top: 20px;
}

.flex-alignment {
  display: flex;
  justify-content: center;
}
.edit-button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #f0df20;
  color: black;
  padding: 10px;
  border: none;
  width: 140px;
  margin-left: 10px;
  font-size: 20px;
  border-radius: 10px;
}

.chat-box-design {
  padding: 8px 40px;
  background: #adb8b4;
  width: auto;
  max-width: fit-content;
  border-radius: 0 10px 10px 10px;

  span {
    font-size: 16px;
    font-weight: 500;
  }
}

.sender-message-alignment {
  span {
    padding: 10px 40px;
    background: #79c9c8;
    font-size: 18px;
    font-weight: 500;
    color: azure;
    border-radius: 10px 0 10px 10px;
  }
}

.profileBox {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 18px;
  background-color: #adb8b4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  &.senderCHat {
    margin-left: 10px;
    background-color: #79c9c8;
  }
}

.chat-box-alignment {
  border: 1px solid #d0d0d0;
  padding: 20px;
  border-radius: 4px;
  background: #f5f5f5;
}


.chat-box-header-design {
  .chat-profile-grid {
    display: grid;
    grid-template-columns: 55px 1fr;
    gap: 16px;
    padding: 0 0 16px 0;
    align-items: center;
    margin: 0 0 10xpx 0;
    border-bottom: 1px solid #d0d0d0;
    .chat-profile-grid-items {
      img {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        object-fit: cover;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        color: #000;
        margin: 0;
        line-height: 24px;
      }

      span {
        font-size: 12px;
        display: block;
        color: #000;
        opacity: .5;
        line-height: 18px;
      }
    }
  }
}

.child-text-style {
  span {
    font-size: 12px;
    display: block;
    color: #000;
    opacity: .5;
    font-weight: 500;
    line-height: 16px;
  }
}

.child-text-style-new {
  span {
    font-size: 12px;
    display: block;
    color: #000;
    opacity: .5;
    text-align: right;
    font-weight: 500;
    line-height: 16px;
  }
}

.add-alignment {
  position: relative;
  i {
    cursor: pointer;
    z-index: 99;
  }
  input {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.plus-icon{
  background-color:#a2a3b7 ;
  border-radius: 50%;
  margin: 10px;
  padding: 10px;
  color: white;
}